@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mochiy+Pop+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sofia&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat";
}

@font-face {
  font-family: "ClashDisplay";
  src: local("ClashDisplay"),
    url("./font/clashDisplay-Variable.ttf") format("truetype");
}

.hello {
  font-family: Sofia;
  color: #1f8a70;
  font-weight: 400;
}

.banner {
  overflow: hidden;
  white-space: nowrap;
  margin: 0 auto;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.servicesCard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.howToLearn {
  background: linear-gradient(180deg, #181818 0%, #565454 100%);
}

.waitlistPhoneInput .form-control {
  height: 65px !important;
  width: 100% !important;
  border: 1px solid #ccc !important;
  border-radius: 8px !important;
}

.waitlistPhoneInput .special-label {
  display: none !important;
}

body::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #000000;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin-top: 6px;
  font: inherit;
  color: #1f8a70;
  width: 1em;
  height: 1em;
  border: 1px solid #1f8a70;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: flex;
  align-items: center;
}
input[type="radio"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #1f8a70;
  border-radius: 50%;
}
input[type="radio"]:checked::before {
  transform: scale(0.7);
}
